import React from "react"
import styled from "styled-components"
import { TwitterTweetEmbed } from "react-twitter-embed"
import Layout, { ContentWrapper } from "../../components/layout"
import SEO from "../../components/seo"
import { Header } from "../../components/theme"
import { BackToLab } from "../../components/link"

const links = [
  {
    href:
      "https://www.fastcodesign.com/90136943/want-to-learn-a-new-language-with-this-ar-app-just-point-and-tap",
    title: "Want To Learn A New Language? With This AR App, Just Point And Tap",
  },
  {
    href:
      "https://next.reality.news/news/apple-ar-arkit-machine-learning-come-together-making-smarter-augmented-reality-0179507/",
    title:
      "Apple AR: ARKit & Machine Learning Come Together Making Smarter Augmented Reality « Next Reality",
  },
  {
    href:
      "https://web.archive.org/web/20170827014503/http://vrinside.jp/news/arkit-machine-learning/",
    title:
      "ARKitと機械学習を用いて、部屋の中にある物の名前を表示するデモが登場 | VR Inside",
  },
  {
    href: "https://www.leiphone.com/news/201708/UFaNMjBGQ2wD7lzt.html",
    title: "ARKit 和机器学习结合，AR 变得更聪明啦！",
  },
]
const Arkit = ({ location }) => {
  return (
    <Layout location={location}>
      <SEO title="ARKit Translator App Demo" />
      <Header>ARKit Translator App Demo</Header>
      <BackToLab />
      <ContentWrapper>
        <div>
          With the very awesome ARKit coming out Autumn 2017 on part of iOS11, I
          started doing demos on ARKit combining my ideas with th power of
          Augmented Reality. I made a{" "}
          <a
            href="https://twitter.com/franxyzxyz/status/897091233872465922"
            target="_blank"
            rel="noreferrer noopener"
          >
            ARKit demo
          </a>{" "}
          of translating objects captured through camera and projecting the
          result where the objects are in reality with the new CoreML API in
          iOS11.
        </div>
        <TwitterTweetEmbed tweetId={"897091233872465922"} />
        <ul>
          {links.map(link => (
            <li key={link.href}>
              [
              <a target="_blank" rel="noreferrer noopener" href={link.href}>
                {link.title}
              </a>
              ]
            </li>
          ))}
        </ul>
      </ContentWrapper>
    </Layout>
  )
}
export default Arkit
